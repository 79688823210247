
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingModal from "../../loader/LoadingModal";
import "../../styles/posstyle.css";
import { chageKDSKotAllItemsStatusAPI, chageKDSKotStatusAPI, chageKotItemStatusAPI, kdsBar, timmerValue } from "../../assets/api/BaseURL";
import { useToast } from "../../../context/ToastContext";
import printJS from "print-js";

export default function Bar() {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);


  const [KOTData, setKOTData] = useState([]);
  const [itemsData, setItesData] = useState([]);

  const [minTime, setMinTime] = useState([]);
  const [maxTime, setMaxTime] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);

  const [token, setToken] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {

      if (localStorage.getItem("staff_type") === 'bar') {
        setToken(localStorage.getItem("token"));
      } else {
        navigate("/" + localStorage.getItem("staff_type"));
      }


    } else {
      logout()
    }

  }, [token]);

  const getKotData = async (e) => {

    // const data = { id: 1 };
    setIsLoading(true)

    try {
      const response = await axios.get(kdsBar, {
        // params: {
        //     id: "1",
        // },
        headers: { authorization: `Bearer ${token}` },
      });

      console.log(response)

      if (response.data) {
        setKOTData(response.data.kotData)
        setItesData(response.data.itemsData)

        const tempSelectedItems = selectedItems.filter(si =>
          response.data.itemsData.some(item =>
            item.product_id === si.itemsId && item.total_quantity === si.totalQuantity
          )
        );
        setSelectedItems(tempSelectedItems)



        if (response.data.dateTime.length > 0) {
          setMinTime(response.data.dateTime[0].min_creation_date)
          setMaxTime(response.data.dateTime[0].max_creation_date)
        }

      }




      setIsLoading(false)
      // satTimer(timmerValue)



    } catch (error) {

      console.log(error);
      setIsLoading(false)
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
        if (error.response && error.response.data.code) {
          logout()
        }
      } else {
        addToast("error", error.message);
      }
      // satTimer(timmerValue)


    }

  };

  useEffect(() => {
    if (token) {
      getKotData()
    }



  }, [token]);

  const chageKotItemStatus = async (kotid, itemid) => {

    setIsLoading(true)
    try {
      const response = await axios({
        method: "put",
        url: chageKotItemStatusAPI,
        data: {

          kotid: kotid,
          itemid: itemid,
          kotTypes: 'bar',
        },
        headers: { authorization: `Bearer ${token}` }
      });

      // console.log(response.status)
      if (response.status === 200) {
        // alert("dd")
        getKotData()
      } else {
        setIsLoading(false)

      }

    } catch (error) {
      setIsLoading(false)

      console.log(error);
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }
    }

  };

  const chageKotStatus = async (kotid, itemKotStatus) => {
    setIsLoading(true)
    try {
      const response = await axios({
        method: "put",
        url: chageKDSKotStatusAPI,
        data: {

          itemKotStatus: itemKotStatus,
          kotid: [kotid],
          kotType: 'bar',
        },
        headers: { authorization: `Bearer ${token}` }
      });

      console.log(response.status)
      if (response.status === 200) {
        // alert("dd")
        getKotData()
      } else {
        setIsLoading(false)
      }


    } catch (error) {
      console.log(error);
      setIsLoading(false)
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }

    }
  };

  const clickItemsQuantity = async (totalQuantity, itemsId, itemName) => {
    let data = { totalQuantity, itemsId, itemName };

    setSelectedItems((prevItems) => {
      const itemExists = prevItems.some((item) => item.itemsId === itemsId);

      if (itemExists) {
        // Remove the item if it already exists
        return prevItems.filter((item) => item.itemsId !== itemsId);
      } else {
        // Add the new item if it doesn't exist
        return [...prevItems, data];
      }
    });

    // Log updated state after update completes
    setTimeout(() => console.log(selectedItems), 0); // Log after state update (since setState is asynchronous)
  };


  const isItemPresentInSelecteItem = (itemsId) => {
    return selectedItems.some((item) => item.itemsId === itemsId);
  };

  const clickSendAll = async () => {
    // const id = KOTData.filter(element => element.status === 1).map(element => element.id);
    // console.log(id);
    setIsLoading(true)

    try {
      const response = await axios({
        method: "put",
        url: chageKDSKotAllItemsStatusAPI,
        data: {
          items: selectedItems,
          minTime: minTime,
          maxTime: maxTime,

        },
        headers: { authorization: `Bearer ${token}` }
      });

      console.log(response.status)
      if (response.status === 200) {
        setSelectedItems([])
        getKotData()
      }
      else {
        setIsLoading(false)

      }
    } catch (error) {
      console.log(error);
      setIsLoading(false)
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }

    }
  };



  const chageKDSAllItemsStatus = async (totalQuantity, itemsId, itemName) => {
    setIsLoading(true)

    try {
      const response = await axios({
        method: "put",
        url: chageKDSKotAllItemsStatusAPI,
        data: {

          totalQuantity: totalQuantity,
          itemsId: itemsId,
          itemName: itemName,
          minTime: minTime,
          maxTime: maxTime,

        },
        headers: { authorization: `Bearer ${token}` }
      });

      console.log(response.status)
      if (response.status === 200) {
        // alert("dd")
        getKotData()
      }
      else {
        setIsLoading(false)

      }
    } catch (error) {
      console.log(error);
      setIsLoading(false)
      if (error.response && error.response.data.message) {
        addToast("error", error.response.data.message);
      } else {
        addToast("error", error.message);
      }

    }
  };
  const logout = async () => {
    localStorage.clear()
    navigate("/login");

  }
  const [seconds, setSeconds] = useState(0);
  const [gettimer, satTimer] = useState(timmerValue);
  // const [getTimerValue, satTimerValue] = useState(5);





  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(seconds + 1);

      if (gettimer > 0) {
        satTimer(gettimer - 1)
      }

      if (gettimer === 1) {
        // alert("dfdf")
        getKotData()
        satTimer(timmerValue)
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds]);

  const [printKOTDetails, setPrintKOTDetails] = useState({
    kot_number: "",
    kot_details: [],
  });
  useEffect(() => {
    // alert()
    if (printKOTDetails.kot_number) {
      const handlePrintKOT = async () => {
        if (printKOTDetails.kot_number !== "") {
          printJS({
            printable: "contentToPrintKOT",
            type: "html",
            targetStyles: ["*"],

          });

          setPrintKOTDetails({
            kot_number: "",
            kot_details: [],
          });
        }
      };
      handlePrintKOT();
    }
  }, [printKOTDetails.kot_number]);

  function getCurrentDate(format = 'YYYY-MM-DD') {
    const today = new Date();

    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');

    if (format === 'YYYY-MM-DD') {
      return `${year}-${month}-${day}`;
    } else if (format === 'MM-DD-YYYY') {
      return `${month}-${day}-${year}`;
    } else if (format === 'DD-MM-YYYY') {
      return `${day}-${month}-${year}`;
    } else if (format === 'long') {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return today.toLocaleDateString(undefined, options);
    } else {
      throw new Error('Invalid format specified');
    }
  }

  return (
    <div><div>
      <div className="main-panel layout">
        <div className="content-wrapper contentdiv">
          <div className="row">
            <div className="headernav">
              <div className="row">
                <div className="col-lg-4 text-left">
                  <h4 className="welcome-text">
                    Hello,{" "}
                    <span className="text-black fw-bold">
                      {localStorage.getItem('staff_restaurant_name')}
                    </span>

                  </h4>

                </div>
                <div className="col-lg-4 text-center">
                  <h6 className="welcome-text">
                    <b>Bar's Kot</b>
                  </h6>
                  <h6 className="welcome-text">
                    Name : {localStorage.getItem('staff_name')}({localStorage.getItem('user_role')})
                  </h6>

                </div>
                <div className="col-lg-4 text-right">
                  <h4 className="welcome-text  px-3">
                    This page will refresh in <i className="menu-icon mdi mdi-timer menu-icon vsalign" style={{ fontSize: "20px" }}></i>  <span className="text-danger fw-bold"> {gettimer}</span>  seconds
                  </h4>
                  <button onClick={logout} className="newordbtn" type="button">
                    Logout
                  </button>
                </div>


              </div>
              <hr />
            </div>




          </div>
          <div className="row flex-grow">
            <div className="col-lg-2 d-flex flex-column">
              <div className="sidemnuovr ps-4 mt-4 menunm">


                {itemsData.map((i, index) => (
                  <div
                    className="card card-rounded grid-margin"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card-body ">


                      <div className="row mt-2">
                        <div className="d-sm-flex justify-content-between">

                          <div className="col-9">
                            <span className="fw-bold">{i.product_name}</span>
                          </div>

                          <div className="col-2">
                            <button
                              type="submit"
                              // className={"btn btn-outline-success kotallitemsreadybtn"}
                              // onClick={() => { chageKDSAllItemsStatus(i.total_quantity, i.product_id, i.product_name) }}
                              className={isItemPresentInSelecteItem(i.product_id) ? "btn btn-success kotallitemsreadybtn" : "btn btn-outline-success kotallitemsreadybtn"}
                              onClick={() => { clickItemsQuantity(i.total_quantity, i.product_id, i.product_name) }}

                            >
                              {i.total_quantity}
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                ))}


              </div>
            </div>
            <div className="col-lg-10 d-flex flex-column">
              <br />
              {KOTData.length > 0 ? <>
                <button className="newordbtn " onClick={clickSendAll} disabled={selectedItems.length == 0 ? true : false} style={{ background: "#45b39d", width: "100px" }} type="button">
                  Send All
                </button>
                <br />
              </> : <></>}

              <div className="row flex-grow">
                <div>
                  <div className="row mr10">

                    {/* kot data show */}

                    {KOTData.map((i, index) => (
                      <>
                        {i.kot_details && i.kot_details.length > 0 && <div key={index} className="col-lg-2 col-md-5 col-12 stretch-card __web-inspector-hide-shortcut__">
                          <div className="card card-rounded grid-margin">
                            <div className="card-body kotcrd">
                              <div className="row inque">
                                <div className="d-sm-flex justify-content-between mt-1 mb-1">
                                  <div className="">
                                    <p className="mb-0">
                                      <strong>KOT - {i.kot_number}</strong>
                                    </p>
                                    <p className="mb-0">#{i.order_numbe} : {i.order_type}</p>
                                  </div>
                                  <div className="text-right">
                                    <p className="mb-0">
                                      <strong>{i.time}</strong>
                                    </p>

                                    {i.status === 0 && <p className="mb-0"><strong>Queue</strong></p>}
                                    {i.status === 1 && <p className="mb-0"><strong>Cooking</strong></p>}
                                    {i.status === 2 && <p className="mb-0"><strong>Ready</strong></p>}
                                    {i.status === 3 && <p className="mb-0"><strong>Send</strong></p>}
                                    {i.status === 4 && <p className="mb-0 text-danger"><strong>Cancelled</strong></p>}

                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="crdscrl">
                                {i.kot_details.map((j, jIndex) => (
                                  <div className="row" key={j.id}>
                                    <div className="col-9">
                                      <p>
                                        <strong>{j.quantity}</strong>&nbsp;&nbsp;&nbsp;&nbsp;{j.product_name}
                                      </p>
                                    </div>
                                    <div className="col-2">
                                      {
                                        j.item_kot_status === 3 ? <>

                                          {/* <button
                                                                                type="submit"
                                                                                className={"btn btn-danger kotitemsbtn"}
                                                                            >
                                                                                <i className="mdi  mdi-close" />
                                                                            </button> */}

                                        </> : <>

                                          <button
                                            type="submit"
                                            // className={j.item_kot_status === 0 ? "btn btn-outline-success kotitemsbtn" : "btn btn-success kotitemsbtn"}
                                            // onClick={() => { chageKotItemStatus(i.id, j.id) }}
                                            className={j.item_kot_status === 0 && !isItemPresentInSelecteItem(j.product_id) ? "btn btn-outline-success kotitemsbtn" : "btn btn-success kotitemsbtn"}
                                            onClick={() => { chageKotItemStatus(i.id, j.id) }}
                                            disabled={isItemPresentInSelecteItem(j.product_id)}
                                          >
                                            <i className="mdi mdi-check" />
                                          </button>
                                        </>
                                      }
                                    </div>

                                  </div>
                                ))}


                              </div>
                              <div className="row mt-2">
                                <div className="d-sm-flex justify-content-between">
                                  <div className="col-8">
                                    {/* <button
                                                                                type="submit"
                                                                                className={i.status === 0 ? "btn btn-inque text-center finishbtn" : i.status === 1 ? "btn btn-success text-center finishbtn" : "btn btn-success text-center finishbtn"}
                                                                                onClick={()=>{chageKotStatus(i.id)}}
                                                                            >
                                                                                {i.status === 0 && "Ready"}
                                                                                {i.status === 1 && "sent"}
                                                                                 



                                                                            </button> */}

                                    {i.status === 0 ?
                                      <button
                                        type="submit"
                                        className={"btn btn-inque text-center finishbtn"}
                                        onClick={() => { chageKotStatus(i.id, 1) }}
                                      >

                                        Ready
                                      </button> :
                                      <>
                                        {i.status === 4 ?

                                          <button
                                            type="submit"
                                            className={"btn btn-danger text-center finishbtn"}
                                            onClick={() => { chageKotStatus(i.id, 4) }}
                                          >
                                            Cancelled
                                          </button>
                                          :
                                          <button
                                            type="submit"
                                            className={"btn btn-success text-center finishbtn"}
                                            onClick={() => { chageKotStatus(i.id, 2) }}
                                          >

                                            Send

                                          </button>
                                        }

                                      </>
                                    }




                                  </div>
                                  <div className="text-right col-4">
                                    <button
                                      type="submit"
                                      className="btn btn-outline-dark btn-fw printbtn"
                                      onClick={(
                                        e
                                      ) => {
                                        e.preventDefault();
                                        setPrintKOTDetails(
                                          i
                                        );
                                      }}
                                    >
                                      <i className="fa fa-print" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>}

                      </>

                    ))}





                  </div>
                </div>
              </div>





            </div>
          </div>
        </div>
      </div>


      <div style={{ display: "none" }}>
        <div id="contentToPrintKOT">
          <div className="printcss">

            <div className="col-12">
              {printKOTDetails.kot_number && (
                <>
                  <p className="gst thank">
                    <strong>{printKOTDetails.kot_prefix} - {printKOTDetails.kot_number} {printKOTDetails.status === 4 ? "(Cancelled)" : ""}</strong>
                  </p>

                </>

              )}
            </div>

            <table className="tblecss info">
              <tbody>
                <tr>
                  <td>
                    Date : <span>{getCurrentDate('DD-MM-YYYY')}</span>
                  </td>
                  <td className="txtrg">
                    Time : <span>{printKOTDetails.time}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>#{printKOTDetails.order_numbe} ({printKOTDetails.order_type})</strong>
                  </td>
                  {/* {orderType === "DINE IN" && (
                    <td className="txtrg">
                  
                      {selectedTableDetails
                        ? selectedTableDetails.table_no
                        : ""}
                    </td>
                  )} */}
                </tr>
              </tbody>
            </table>
            <table className="tblecss2">
              <thead>
                <tr>
                  <th className="thcss itemth">Item</th>
                  {/* <th className="thcss qtyth txtct">Special Note</th> */}
                  <th className="thcss qtyth txtrg">Qty</th>
                </tr>
              </thead>

              <tbody>
                {printKOTDetails.kot_details.map((order, index) => (
                  <tr key={index}>
                    <td className="tdcss nametd">
                      {order.product_name}
                      {order.product_special_note ? <> <i>
                        <br />
                        {order.product_special_note}
                      </i></> : <></>}


                      {order.variant_details && <>
                        {JSON.parse(order.variant_details).combination_details && JSON.parse(order.variant_details).combination_details.map((i) => (
                          <><br /><b>{i.attribute_name}: </b> {i.attribute_value_name}</>
                        ))}
                      </>}

                      {order.addons_items_details && JSON.parse(order.addons_items_details).map((i) => (
                        <> <br /> <i>{i.addon_item_name}</i></>
                      ))}
                    </td>

                    <td className="tdcss qtytd txtrg">{order.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
      <LoadingModal isLoading={isLoading} />

    </div>
  )
}
